import { ChainId, Currency, ERC20Token, Pair } from '@pancakeswap/sdk'
import { Button, Text, useModal, Flex, Box, NumericalInput, CopyButton, Tag } from '@pancakeswap/uikit'
import styled, { css } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'

import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { formatNumber } from '@pancakeswap/utils/formatBalance'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useWeb3React } from '@pancakeswap/wagmi'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import AddToWalletButton from '../AddToWallet/AddToWalletButton'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useAllTokens } from 'hooks/Tokens'
import { useMemo } from 'react'
import { DropdownIcon } from '../../../public/icon'
import { isMobile } from 'react-device-detect'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`
export const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm', isShadow: false })<{
  zapStyle?: ZapStyle
}>`
  padding: 0 !important;
  margin: 0 !important;
  ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      border: 1px solid ${theme.colors.cardBorder};
      border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      height: auto;
    `};
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;
  background-color: #141414;
  border-radius: 20px;
  border: none;
`
const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
  box-shadow: ${({ theme, error }) => theme.shadows[error ? 'warning' : '']};
  ${({ zapStyle }) =>
    !!zapStyle &&
    css`
      border-radius: 0px 16px 16px 16px;
    `};
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showBUSD?: boolean
  tokens?: { [address: string]: ERC20Token }
  hideManage?: boolean
  showNative?: boolean
  title?: string
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  disabled,
  error,
  showBUSD = true,
  tokens,
  hideManage,
  showNative,
  title,
}: CurrencyInputPanelProps) {
  const { account, chainId } = useWeb3React()
  const { chainId: appChainId } = useActiveChainId()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const amountInDollar = useBUSDCurrencyAmount(
    showBUSD ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      tokens={tokens}
      hideManage={hideManage}
      showNative={showNative}
    />,
  )

  const defaultTokens = useAllTokens()
  const tags = useMemo((): string[] => {
    if (!defaultTokens || !token) return []
    // @ts-ignore
    return defaultTokens[token.address]?.tags || []
  }, [defaultTokens, token])

  return (
    <Box position="relative" id={id}>
      <Flex alignItems="center" justifyContent="space-between" />
      {(label || title) && (
        <Text small color="#b4b4b4" medium marginBottom={2}>
          {label || title}
        </Text>
      )}
      <InputPanel>
        <Container
          as="label"
          style={{
            maxWidth: isMobile ? '72vw' : '100%',
          }}
          zapStyle={zapStyle}
          error={error}
        >
          <Flex justifyContent="space-between" alignItems="center" padding="16px 24px">
            <Flex>
              {beforeButton}
              <CurrencySelectButton
                zapStyle={zapStyle}
                className="open-currency-select-button"
                selected={!!currency}
                style={{ background: 'none', border: 0, marginBottom: 12 }}
                onClick={() => {
                  if (!disableCurrencySelect) {
                    onPresentCurrencyModal()
                  }
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {pair ? (
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                  ) : null}
                  {pair ? (
                    <Text id="pair" bold fontSize="20px" fontWeight={700} color="#FBFBFB">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </Text>
                  ) : (
                    <Text id="pair" bold fontSize={currency?.symbol ? '20px' : '12px'} fontWeight={700} color="#FBFBFB">
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length,
                          )}`
                        : currency?.symbol) || t('Select a currency')}
                    </Text>
                  )}
                  {!disableCurrencySelect && <DropdownIcon />}
                </Flex>
              </CurrencySelectButton>
              {token && tokenAddress ? (
                <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                  {tags.map((tag) => (
                    <Tag variant="success" outline scale="sm">
                      {tag}
                    </Tag>
                  ))}
                  {/* <CopyButton
                    width="16px"
                    buttonColor="textSubtle"
                    text={tokenAddress}
                    tooltipMessage={t('Token address copied')}
                    tooltipTop={-20}
                    tooltipRight={40}
                    tooltipFontSize={12}
                  /> */}
                  <AddToWalletButton
                    variant="text"
                    style={{ background: 'none' }}
                    p="0"
                    height="auto"
                    width="fit-content"
                    tokenAddress={tokenAddress}
                    tokenSymbol={token.symbol}
                    tokenDecimals={token.decimals}
                    tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                  />
                </Flex>
              ) : null}
            </Flex>
            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <InputRow
                style={{ display: 'flex', justifyContent: 'end', maxWidth: '100%' }}
                selected={disableCurrencySelect}
              >
                {account && (
                  <Text
                    onClick={!disabled && onMax}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      display: 'inline',
                      cursor: 'pointer',
                    }}
                    overflow="hidden"
                    fontSize="10px"
                    color="#B1B4BD"
                    fontWeight="700"
                    textAlign="right"
                  >
                    {!hideBalance && !!currency
                      ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                      : ' -'}
                  </Text>
                )}
              </InputRow>
              <LabelRow
                style={{
                  maxWidth: isMobile ? '72vw' : '100%',
                }}
              >
                <NumericalInput
                  error={error}
                  disabled={disabled}
                  className="token-amount-input"
                  value={value}
                  onBlur={onInputBlur}
                  onUserInput={(val) => {
                    onUserInput(val)
                  }}
                  style={{
                    textAlign: 'right',
                    fontSize: '20px',
                    fontWeight: 700,
                    color: '#FBFBFB',
                  }}
                  autoFocus={id === 'swap-currency-input' && (chainId === ChainId.TBSC || appChainId === ChainId.TBSC)}
                />
              </LabelRow>
            </Flex>
          </Flex>
        </Container>
        {disabled && <Overlay />}
      </InputPanel>
    </Box>
  )
}
